import React, {useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {definition as faSlidersH} from "@fortawesome/free-solid-svg-icons/faSlidersH";
import {parseQueryParams, useSearch, useSearchQuery} from "../../../../../reducers/product/search";
import {isSuccess, reset} from "../../../../../reducers/networkStateReducer";
import {Link, useHistory} from "react-router-dom"
import {SortOrderSelector} from "../SortOrderSelector";
import {PAGE_SIZE} from "./ResultSection";
import {SliderRange} from "../../../../common/SliderRange";
import {
    BrandOption,
    CategoryOption,
    ProductConnection,
    SearchReq,
    SelectOption
} from "../../../../../reducers/product/types";
import {useI18n, WithPlaceholders} from "../../../../../i18n/I18nSupport";
import {useSchema} from "../../../../../reducers/schema";
import {useFindBrand} from "../../../../../reducers/brands/findBrand";
import {useBrandList} from "../../../../../reducers/brands/searchBrands";
import {useBrandsSearchQuery} from "../../../../../reducers/brands";
import {ImageViewPort} from "../../../../common/ImageViewPort";
import {ManagedBrand} from "../../../../../reducers/brands/types";
import {useDeleteBrand} from "../../../../../reducers/brands/delete";
import {useProduct} from "../../../../../reducers/product/get";

import { useParams } from "react-router-dom";
import {useBrandData} from "../../../../../reducers/brandData/get";


interface BrandData {
    brandName?: string;
    price?: string;
    isIndividual: boolean;
    slug?: string;
}

function isCategoryOptions(option: SelectOption): option is CategoryOption {
    return option.type === "CATEGORIES"
}

function isBrandOptions(option: SelectOption): option is BrandOption {
    return option.type === "BRANDS"
}

interface MobileFilterSectionProps extends FilterSectionProps {
    lastResult?: ProductConnection
    searchReq: SearchReq
}

export function MobileFilterSection({categories, brands, lastResult, searchReq}: MobileFilterSectionProps){

    const [isClicked, setClicked] = useState(false)

    const {tx, ts} = useI18n("searchResult.filters")

    return (
        <>
            <div className="d-sm-none d-block " style={{border:"none"}}>
                <button className={`btn btn-light w-100 text-left border-0 pl-0 bg-transparent ${!isClicked && "collapsed"}`} type="button"
                        data-toggle="collapse" data-target="#collapseExample1" aria-expanded={"true" && isClicked}
                        aria-controls="collapseExample1" onClick={() => {setClicked(!isClicked)}}>
                    <FontAwesomeIcon icon={faSlidersH} size={"lg"}/> Categories
                </button>
                <div className={`collapse ${isClicked && "show"}`} id="collapseExample1">
                    {lastResult?.pageInfo.resultPageData?.selectOptions?.map(option => {
                        if(isBrandOptions(option)){
                            return <BrandSelection key={option.type} list={option} label={ts`brands`} setClicked={setClicked} isClicked={isClicked}/>
                        }
                    })}
                </div>
            </div>
        </>
    )


}

interface ProductViewMobileFilterSectionProps extends FilterSectionProps{
    pageSize: number;
}

export function ProductViewMobileFilterSection({pageSize}: ProductViewMobileFilterSectionProps){

    const [isClicked, setClicked] = useState(false)
    const {tx, ts} = useI18n("searchResult.filters")

    const [brandsList, getBrandsList, clear] = useBrandList();

    useEffect(() => {
        getBrandsList({}, 0, 10);
    }, []);

    if (!isSuccess(brandsList) ) return null;

    return (
        <>
            <div className="d-sm-none d-block " style={{border:"none"}}>
                <button className={`btn btn-light w-100 text-left border-0 pl-0 bg-transparent ${!isClicked && "collapsed"}`} type="button"
                        data-toggle="collapse" data-target="#collapseExample1" aria-expanded={"true" && isClicked}
                        aria-controls="collapseExample1" onClick={() => {setClicked(!isClicked)}}>
                    <FontAwesomeIcon icon={faSlidersH} size={"lg"}/> Categories
                </button>
                <div className={`collapse ${isClicked && "show"}`} id="collapseExample1">
                    <ProductViewBrandSelection brand={brandsList.data.data} pageSize={pageSize} setClicked={setClicked} isClicked={isClicked}/>

                    {/*{lastResult?.pageInfo.resultPageData?.selectOptions?.map(option => {*/}
                    {/*    if(isBrandOptions(option)){*/}
                    {/*        return <BrandSelection key={option.type} list={option} label={ts`brands`} setClicked={setClicked} isClicked={isClicked}/>*/}
                    {/*    }*/}
                    {/*})}*/}
                </div>
            </div>
        </>
    )

}

interface ProductViewSingleBrandSelectionProps {
    brand:{     id?: string | undefined;     name?: string | undefined;     tag?: string | undefined;     tags?: string[] | undefined; }[];
    pageSize?: number,
    setClicked: React.Dispatch<React.SetStateAction<boolean>>;
    isClicked:boolean
}

function ProductViewBrandSelection({brand, pageSize,setClicked,isClicked }: ProductViewSingleBrandSelectionProps) {
    const [req, update] = useSearchQuery();
    const { tx } = useI18n("searchResult.filters.singleSelection");
    const { productSlug } = useParams<{ productSlug: string }>();
    const [brandDataSchema,getBrandsData] = useBrandData();
    const [brandData, setBrandData] = useState<BrandData[] >([]);

    useEffect(() => {
        getBrandsData();
    }, []);

    useEffect(() => {
    }, [brandData]);

    useEffect(()=>{
        isSuccess(brandDataSchema) && setBrandData(brandDataSchema.data[0].brands)
        isSuccess(brandDataSchema) && console.log("brandSchema",brandDataSchema.data[0])
    },[brandDataSchema])


    const getLinkPath = (brandName: string,tag:string): string => {
        const brand = brandData.find(b => b.brandName === brandName);
        if (brand) {
            return brand.isIndividual
                ? `/products/${brand.slug || 'default'}`
                : `${update({ ...req, tags: [tag], first: 12, after: 0 })}`;
        }
        return '/products/default';
    };


    const handleClick = () => {
        setClicked(!isClicked);
    };

    useEffect(() => {
        const brandInfo = brand
            .map(b => ({
                id: b.id,
                name: b.name,
            }))
            .filter(info => info.id !== undefined && info.name !== undefined); // Filter out any undefined values
        ;
        console.log("Brand Info:", brandInfo);
        console.log("Product Slug:", productSlug);
        console.log("Req tags:", req.tags);
    }, [brand, req.tags, productSlug]);


    if (!brand || !brand.length) return null;

    return (
        <>
            <p className="mb-2 mt-3 font-weight-bold align text-center featured-text">Featured Tickets</p>
            <ul className="sidebar-list sidebar-list-mobile">
                {brand
                    ?.filter(({ name }) => name !== "None" && name !== "Sponsor")
                    .map((brand) => (
                        <WithPlaceholders value={{ singleSelection: brand }} key={brand.id}>
                            <li key={brand.id} className="sidebar-list__item">
                                <Link
                                    to={getLinkPath(brand.name!!, brand.tag!!)}
                                    className="check-container text-link"
                                    onClick={handleClick}
                                    replace
                                >
                                    {tx`name`}
                                    <input
                                        type="checkbox"
                                        checked={(req.tags ?? []).includes(brand.id!!)}
                                        readOnly
                                    />
                                    <span className="checkmark" />
                                </Link>
                            </li>
                        </WithPlaceholders>
                    ))}
            </ul>
        </>
    );
}


interface SingleSelectionProps {
    list?: SelectOption,
    label: string,
    pageSize?: number,
}
interface SingleBrandSelectionProps {
    list?: SelectOption,
    label: string,
    pageSize?: number,
    setClicked: React.Dispatch<React.SetStateAction<boolean>>;
    isClicked:boolean
}

function SingleSelection({list, label,pageSize}: SingleSelectionProps) {

    const [req, update] = useSearchQuery();
    const {tx} = useI18n("searchResult.filters.singleSelection")

    if (!list || !list.data.length) return null

    return <>
        <p className="mb-2 font-weight-bold category-text">{label}</p>
        <ul className="sidebar-list">
            { list?.data.map( (category,index) =>
                <WithPlaceholders value={{ singleSelection: category}} key={index}><li key={category.id} className={`sidebar-list__item ${req.tags?.includes(category.id) == true ? 'active' : ''}`}>
                    <Link
                        to={`${update({
                            ...req,
                            tags: [...(req.tags || []).filter(a => !list.data.includes(category)), ...([category.id])],
                            first: pageSize ?? PAGE_SIZE, after: 0
                        })}`}
                        className={"sidebar-list__item-link"}
                        replace
                    >
                        {tx`name`}
                    </Link>
                </li>
                </WithPlaceholders>
            )}
        </ul>
    </>
}


function MultiSelection({ list, label, pageSize }: SingleSelectionProps) {
    const [req, update] = useSearchQuery();
    const { tx } = useI18n("searchResult.filters.multiSelection");
    const [brandsList, getBrandsList, clear] = useBrandList();
    const [brandLogos, setBrandLogos] = useState<Record<string, string>>({}); // Fix: Explicitly set type
    const [brand, findBrand] = useFindBrand('');
    // const productLink = !!item.productSlug ? `/products/${item.productSlug}` : `/product/${item.id}`
    // const productLink = !!item.productSlug ? `/products/general-admission` : `/product/${item.id}`
    const fetchedBrands = useRef(new Set());
    const [brandDataSchema,getBrandsData] = useBrandData();
    const [brandData, setBrandData] = useState<BrandData[] >([]);

    useEffect(() => {
        getBrandsData();
        getBrandsList({}, 0, 10);
    }, []);

    useEffect(() => {
    }, [brandData]);

    useEffect(()=>{
        isSuccess(brandDataSchema) && setBrandData(brandDataSchema.data[0].brands)
        isSuccess(brandDataSchema) && console.log("brandSchema",brandDataSchema.data[0])
    },[brandDataSchema])


    const getLinkPath = (brandName: string,tag:string): string => {
        const brand = brandData.find(b => b.brandName === brandName);
        if (brand) {
            return brand.isIndividual
                ? `/products/${brand.slug || 'default'}`
                : `${update({ ...req, tags: [tag], first: 12, after: 0 })}`;
        }
        return '/products/default';
    };

    useEffect(() => {
        if (isSuccess(brandsList) && brandsList.data.data) {
            brandsList.data.data.forEach((brandItem) => {
                const brandId = String(brandItem.id);
                if (brandId && !brandLogos[brandId] && !fetchedBrands.current.has(brandId)) {
                    findBrand(brandId);
                    fetchedBrands.current.add(brandId);
                }
            });
        }
    }, [brandsList]);

    useEffect(() => {
        if (isSuccess(brand) && brand.data.id) {
            const logoValue = brand.data.logo?.value || '/default-logo.png';
            setBrandLogos((prevLogos) => ({
                ...prevLogos,
                [String(brand.data.tag)]: logoValue,
            }));
        }
    }, [brand]);


    if (!list || !list.data.length ) return null;

    const unselectedBrands = list.data.filter(
        (brandItem) => !(req.tags ?? []).includes(brandItem.id)
    );


    return (
        <>
            <p className="mb-2 mt-3 font-weight-bold align text-center featured-text">Featured Tickets</p>
            <ul className="sidebar-list">
                {unselectedBrands
                    .filter(({ name }) => name.en !== "None" && name.en !== "Sponsor")
                    .map((brand, index) => (
                        <WithPlaceholders value={{ multiSelection: brand }} key={index}>
                            <li key={brand.id} className="sidebar-list__item">
                                <Link
                                    to={getLinkPath(brand.name.en, brand.id)}
                                    className="check-container"
                                    replace
                                >
                                    <div className="brand-logo-container">
                                        <img
                                            src={brandLogos[brand.name.en] || "/default-logo.png"}
                                            style={{ objectFit: "contain", width: "100%" }}
                                            alt={`${brand.name.en}-logo`}
                                        />
                                    </div>
                                </Link>
                            </li>
                        </WithPlaceholders>
                    ))}

            </ul>
        </>
    );
}

function BrandSelection({ list, label, pageSize,setClicked,isClicked }: SingleBrandSelectionProps) {
    const [req, update] = useSearchQuery();
    const { tx } = useI18n("searchResult.filters.singleSelection");
    const [brandDataSchema,getBrandsData] = useBrandData();
    const [brandData, setBrandData] = useState<BrandData[] >([]);

    useEffect(() => {
        getBrandsData();
    }, []);

    useEffect(() => {
    }, [brandData]);

    useEffect(()=>{
        isSuccess(brandDataSchema) && setBrandData(brandDataSchema.data[0].brands)
        isSuccess(brandDataSchema) && console.log("brandSchema",brandDataSchema.data[0])
    },[brandDataSchema])


    const getLinkPath = (brandName: string,tag:string): string => {
        const brand = brandData.find(b => b.brandName === brandName);
        if (brand) {
            return brand.isIndividual
                ? `/products/${brand.slug || 'default'}`
                : `${update({ ...req, tags: [tag], first: 12, after: 0 })}`;
        }
        return '/products/default';
    };

    const handleClick = () => {
        setClicked(!isClicked);
    };

    if (!list || !list.data.length) return null;

    return (
        <>
            <p className="mb-2 mt-3 font-weight-bold align text-center featured-text">Featured Tickets</p>
            <ul className="sidebar-list sidebar-list-mobile">
                {list.data
                    .filter(({ name }) => name.en !== "None" && name.en !== "Sponsor")
                    .map((brand) => (
                        <WithPlaceholders value={{ singleSelection: brand }} key={brand.id}>
                            <li key={brand.id} className="sidebar-list__item">
                                <Link
                                    to={getLinkPath(brand.name.en, brand.id)}
                                    className="check-container text-link"
                                    onClick={handleClick}
                                    replace
                                >
                                    {tx`name`}
                                    <input
                                        type="checkbox"
                                        checked={(req.tags ?? []).includes(brand.id)}
                                        readOnly
                                    />
                                    <span className="checkmark" />
                                </Link>
                            </li>
                        </WithPlaceholders>
                    ))}
            </ul>
        </>
    );
}




interface RangeProps {
    label: string,
    rangeKey: string,
    unit?: string,
    value?: number[]
}

export interface FilterSectionProps {
    categories?: CategoryOption
    brands?: BrandOption
    sortOptions?: string[]
}

export function OtherBrands() {

    const [results] = useSearch("result-section")
    let [lastResult, setLastResult] = useState<ProductConnection>();
    const {tx, ts} = useI18n("searchResult.filters")
    const [pageSize,setPageSize] = useState<number>()
    const [schema] = useSchema();

    useEffect(() => {
        if (isSuccess(schema)) {
            setPageSize(schema.data.generalSettings?.searchPageProductSize ?? PAGE_SIZE)
        }
    },[schema])

    useEffect(() =>{
        if (isSuccess(results)) {
            setLastResult(results.data)
        }
    }, [results])

    let searchReq = parseQueryParams(window.location.href);

    return(
        <>
            <div className="col-lg-3 col-sm-4">
                <div className="sidebar mb-3 py-sm-3 py-0">
                    <MobileFilterSection lastResult={lastResult} searchReq={searchReq}/>
                    <div className="d-sm-block d-none">
                        {/*{lastResult?.pageInfo.resultPageData?.selectOptions?.map(option => {*/}
                        {/*    if(isCategoryOptions(option)){*/}
                        {/*        return <SingleSelection pageSize={pageSize} key={option.type} list={option} label={ts`categories`}/>*/}
                        {/*    }*/}
                        {/*    return null*/}
                        {/*})}*/}
                        {lastResult?.pageInfo.resultPageData?.selectOptions?.map(option => {
                            if(isBrandOptions(option)){
                                return <MultiSelection  pageSize={pageSize} key={option.type} list={option} label={ts`brands`}/>
                            }
                            return null
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}

interface SingleBrandSelectionProps2{
    pageSize:number
}

function ProductViewMultiSelection({ pageSize}: SingleBrandSelectionProps2) {
    const [req, update] = useSearchQuery();
    const { tx } = useI18n("searchResult.filters.multiSelection");
    const [brandsList, getBrandsList, clear] = useBrandList();
    const [brandLogos, setBrandLogos] = useState<Record<string, string>>({}); // Fix: Explicitly set type
    const [brand, findBrand] = useFindBrand('');
    const fetchedBrands = useRef(new Set());
    const [brandDataSchema,getBrandsData] = useBrandData();
    const [brandData, setBrandData] = useState<BrandData[] >([]);

    useEffect(() => {
        getBrandsData();
        getBrandsList({}, 0, 10);
    }, []);

    useEffect(() => {
    }, [brandData]);

    useEffect(()=>{
        isSuccess(brandDataSchema) && setBrandData(brandDataSchema.data[0].brands)
        isSuccess(brandDataSchema) && console.log("brandSchema",brandDataSchema.data[0])
    },[brandDataSchema])


    const getLinkPath = (brandName: string,tag:string): string => {
        const brand = brandData.find(b => b.brandName === brandName);
        if (brand) {
            return brand.isIndividual
                ? `/products/${brand.slug || 'default'}`
                : `${update({ ...req, tags: [tag], first: 12, after: 0 })}`;
        }
        return '/products/default';
    };


    useEffect(() => {
        if (isSuccess(brandsList) && brandsList.data.data) {
            brandsList.data.data.forEach((brandItem) => {
                const brandId = String(brandItem.id);
                if (brandId && !brandLogos[brandId] && !fetchedBrands.current.has(brandId)) {
                    findBrand(brandId);
                    fetchedBrands.current.add(brandId);
                }
            });
        }
    }, [brandsList]);


    useEffect(() => {
        if (isSuccess(brand) && brand.data.id) {
            const logoValue = brand.data.logo?.value || '/default-logo.png';
            setBrandLogos((prevLogos) => ({
                ...prevLogos,
                [String(brand.data.tag)]: logoValue,
            }));
        }
    }, [brand]);


    if (!isSuccess(brandsList) ) return null;


    return (
        <>
            <p className="mb-2 mt-3 font-weight-bold align text-center featured-text">Featured Tickets</p>
            <ul className="sidebar-list d-flex flex-column align-items-center">
                {brandsList.data.data
                    .filter(({ name }) => name !== "Sponsor")
                    .map((brand) => (
                        <WithPlaceholders value={{ multiSelection: brand }} key={brand.id}>
                            <li key={brand.id} className="sidebar-list__item">
                                <Link
                                    to={getLinkPath(brand.name!!, brand.tag!!)}
                                    className="check-container text-link d-flex justify-content-center align-items-center"
                                    replace
                                >
                                    <div className="brand-logo-container">
                                        <img
                                            src={brandLogos[brand.name!!] || "/default-logo.png"}
                                            className="brand-logo"
                                            alt={`${brand.name}-logo`}
                                        />
                                    </div>
                                </Link>
                            </li>
                        </WithPlaceholders>
                    ))}
            </ul>
        </>
    );
}


export function ProductViewOtherBrands() {

    const [results] = useSearch("result-section")
    let [lastResult, setLastResult] = useState<ProductConnection>();
    const {tx, ts} = useI18n("searchResult.filters")
    const [pageSize,setPageSize] = useState<number>()
    const [schema] = useSchema();
    const [brandsList, getBrandsList, clear] = useBrandList();
    const shouldShowOtherBrands = isSuccess(brandsList) && brandsList.data.data.length !== 1;


    useEffect(() => {
        if (isSuccess(schema)) {
            setPageSize(schema.data.generalSettings?.searchPageProductSize ?? PAGE_SIZE)
        }
    },[schema])

    useEffect(() =>{
        if (isSuccess(results)) {
            setLastResult(results.data)
        }
    }, [results])

    let searchReq = parseQueryParams(window.location.href);


    return(
        <>
            {/*<div className="sidebar mb-3 py-sm-3 py-0">*/}
            <div className={shouldShowOtherBrands? "sidebar mb-3 py-sm-3 py-0" : "d-none"}>
                <ProductViewMobileFilterSection pageSize={pageSize!!}/>
                <div className="d-sm-block d-none">
                    {/*{lastResult?.pageInfo.resultPageData?.selectOptions?.map(option => {*/}
                    {/*    if(isCategoryOptions(option)){*/}
                    {/*        return <SingleSelection pageSize={pageSize} key={option.type} list={option} label={ts`categories`}/>*/}
                    {/*    }*/}
                    {/*    return null*/}
                    {/*})}*/}
                    {/*{lastResult?.pageInfo.resultPageData?.selectOptions?.map(option => {*/}
                    {/*    if(isBrandOptions(option)){*/}
                    <ProductViewMultiSelection  pageSize={pageSize!!} />
                    {/*    }*/}
                    {/*    return null*/}
                    {/*})}*/}
                </div>
            </div>
        </>
    );
}
